<template>
  <b-field :label="label"
           :label-position="labelPosition"
           :message="error"
           :type="typeLabel">
    <b-datepicker
        @input="onSetDate"
        locale="es-MX"
        editable
        :value="localDate"
        :disabled="disabled"
        :max-date="maxDate"
        :min-date="minDate"
        placeholder="Selecciona una fecha"
        required>
    </b-datepicker>
  </b-field>
</template>

<script>
import Cleave from 'cleave.js';
const cleave = {
  name: 'cleave',
  bind(el, binding) {
    const input = el.querySelector('input')
    input._vCleave = new Cleave(input, binding.value)
  },
  unbind(el) {
    const input = el.querySelector('input')
    input._vCleave.destroy()
  }
}
export default {
  name: "SelectDate",
  directives: { cleave },
  props: {
    label: String,
    labelPosition: String,
    error: String|Array|Object,
    placeholder: String,
    readonly: Boolean,
    disabled: Boolean,
    disableInitialDate: Boolean,
    maxDate: Date,
    minDate: Date,
    value: {},
  },
  data() {
    return {
      maskInput: {
        date: true,
        delimiter: '/',
        datePattern: ['d', 'm', 'Y']
      },
      //localDate: null
    }
  },
  mounted() {

  },
  computed: {
    typeLabel() {
      if (!!this.error) {
        return 'is-danger'
      }
    },
    localDate(){
      if (!this.disableInitialDate) {
        return new Date(this.$moment(this.value || new Date()).format());
      }else if(this.value && this.value.toString() !== '0001-01-01T06:36:36.000000Z'){
        //console.log(this.value) ;
        return new Date(this.$moment(this.value || new Date()).format());
      }
    }
  },
  methods: {
    onSetDate(val) {
      this.$emit('input', val);
    }
  }
}
</script>

<style scoped>

</style>
